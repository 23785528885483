import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { SelectAll } from 'features/eiq-connect/constants/common.constant';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import citationService from 'common/services/citation-service/citation-service';
import { citationListConfig } from './citations.config';
import CitationDetails from './citation-details/citation-details';
import { CitationFilter } from './citation-filter/citation-filter';
import { CitationList } from './citation-list';

export const Citations = observer(() => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        arrow: true,
        info: false,
        occurredAt: true,
        details: false,
        violatingSpeed: false,
        speedLimit: false,
      });
    } else {
      tableControls.hideColumns({
        arrow: false,
        info: false,
        occurredAt: true,
        details: true,
        violatingSpeed: true,
        speedLimit: true,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    const selectedAddresses = eiqConnectStore.selectedAddressesId;
    const isAll = !selectedAddresses || selectedAddresses?.length === 0 || selectedAddresses?.[0] === SelectAll;

    if (!isAll) {
      const addresses = eiqConnectStore.residentDetails?.addresses;

      if (!addresses) {
        tableControls.setFilter('addressId', null, 'AND');
        return;
      }

      const filters = selectedAddresses.map((value) => {
        const index = value as number;
        return { operator: FilterTypesEnum.Equals, value: addresses[index].address.id };
      });

      tableControls.setFilter('addressId', filters, 'AND');
    } else {
      tableControls.setFilter('addressId', null, 'AND');
    }
  }, [eiqConnectStore.selectedAddressesId, eiqConnectStore.residentDetails]);

  const tableControls = useTableControls({
    onSelectedRow: (row: any) => {
      if (isMobile) {
        navigate(`/eiq-connect/citations/${row?.id}/view`);
      }
    },
    onFetch: useCallback(
      (pageLimit: any, filter: string | null | undefined, sort: string | null | undefined) => {
        if (userAuthStore.webGuestResidentId) {
          return citationService.getCitationsByResidentId(pageLimit, filter, sort, userAuthStore.webGuestResidentId);
        }
      },
      [userAuthStore.webGuestResidentId],
    ),
    initialColumns: citationListConfig,
  });

  const isBasePath = location.pathname.endsWith('/citations');
  const isSearchPath = location.pathname.endsWith('/citations/search');

  return (
    <TableContextProvider controls={tableControls}>
      {(isBasePath || isSearchPath) && <CitationList className={isBasePath || isSearchPath ? null : 'eiq-display-none'} />}

      <Routes>
        <Route path="/:id/view" element={<CitationDetails />} />
        <Route path="filter" element={<CitationFilter />} />
      </Routes>
    </TableContextProvider>
  );
});
