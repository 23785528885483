import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DATE_SHORT_MMM_DD_YYYY_H_MMA } from 'common/constans/date.constants';
import { ICitationRead } from 'common/services/citation-service/citation-service.types';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { observer } from 'mobx-react-lite';
import citationService from 'common/services/citation-service/citation-service';
import { useTableContext } from 'common/components/table/table-context';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { Button, Tag } from 'antd';
import { CitationStatus, citationStatusColorMap, citationStatusMap } from 'common/enums/citation-status.enum';
import dayjs from 'dayjs';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { ReactComponent as ViolationSpeedIcon } from 'assets/icons/eiq-connect/violating-speed.svg';
import { ReactComponent as PayFine } from 'assets/icons/eiq-connect/pay-fine.svg';
import EIQImageGallery from 'features/eiq-connect/components/image-gallery/image-gallary';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import './citation-details.scss';
import { EiqDetailsRow } from 'common/components/eiq-details/eiq-details-row';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { getCitationGuestName, getCitationVisitorName } from 'common/helpers/citation.helper';
import { ICheckoutModalProps } from './checkout-modal/checkout-modal.types';
import { CheckoutModal } from './checkout-modal/checkout-modal';
import { notification } from 'common/utils/notification';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import featureStore from 'common/stores/features/feature.store';
import { visitorTypeMap, visitorTypeMapColorMap } from 'common/constans/visitor.constants';
import { TrafficLogVisitorType } from 'common/constans/common.constants';

const CitationDetails = observer(() => {
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  const { id } = useParams();
  const navigate = useNavigate();
  const tableContext = useTableContext();
  const selectedRow = tableContext.getSelectedRow() as ICitationRead;
  const [initialValues, setInitialValues] = useState<ICitationRead>();
  const webGuestResidentId = userAuthStore.webGuestResidentId;
  const { isMobile } = useMedia();

  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStipePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (selectedRow) {
      setInitialValues(selectedRow);
    } else if (webGuestResidentId) {
      citationService
        .getCitationsByResidentId(
          {
            offset: 0,
            limit: 20,
          },
          null,
          '',
          webGuestResidentId,
        )
        .then((result) => {
          tableContext.setSelectedRow(result.items.find((item) => item.id === Number(id)));
        });
    }
  }, [tableContext.getSelectedRow(), eiqConnectStore.residentDetails]);

  const isPaymentDisabled = () => {
    return (
      initialValues?.status === CitationStatus.Canceled ||
      initialValues?.status === CitationStatus.Paid ||
      initialValues?.status === CitationStatus.Pending ||
      !initialValues?.totalPaymentAmount
    );
  };

  const onPayFineClicked = () => {
    setStipePromise(loadStripe(stripePublicKey!));

    citationService.createPayment(Number(id), webGuestResidentId!).then((result) => {
      setClientSecret(result.clientSecret);
    });

    paymentModal.showModal();
  };

  const paymentModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const data: ICheckoutModalProps = {
        totalPaymentAmount: initialValues!.totalPaymentAmount,
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        notification.success({
          message: 'Thank you, your payment has been received',
          duration: 5,
        });
        navigate('/eiq-connect/citations');
      }
    },
  });

  const onTagClick = () => {
    if (isPaymentDisabled()) {
      return;
    }
    onPayFineClicked();
  };

  const getPhotosUrls = () => {
    if (!initialValues) {
      return [];
    }
    return [...initialValues.photosUrl, ...initialValues.licensePlatePhotosUrl];
  };

  const getLicensePlateState = (citation?: ICitationRead) => {
    if (!citation?.licenseState) {
      return `${citation?.licensePlate}`;
    }
    return `${initialValues?.licensePlate}, ${initialValues?.licenseState}`;
  };

  const getCitationNotes = () => {
    return (
      <div className="eiq-details-row">
        We appreciate your cooperation in maintaining a safe and secure community for all residents. Should you have any questions or
        concerns, please do not hesitate to reach out with the citation #{initialValues?.externalId}.
      </div>
    );
  };

  return (
    <div className="eiq-guests-page">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Details" />
      </div>

      <div className="eiq-content">
        <div className="w-100 citation-wrapper">
          <EIQImageGallery items={getPhotosUrls()} />
          {isMobile ? (
            <>
              <div className="eiq-details-row">
                <div className="eiq-details-row__title">Status</div>
                <div className="eiq-details-row-tags">
                  <Tag
                    onClick={onTagClick}
                    className="eiq-tag"
                    color={citationStatusColorMap[initialValues?.status as CitationStatus]}
                    style={{ color: '#292B2E' }}>
                    {citationStatusMap[initialValues?.status as CitationStatus]}
                  </Tag>
                  {initialValues?.guestId && (
                    <Tag
                      className="eiq-tag"
                      color={visitorTypeMapColorMap[initialValues?.ownerType as TrafficLogVisitorType]}
                      style={{ color: '#292B2E' }}>
                      {visitorTypeMap[initialValues?.ownerType as TrafficLogVisitorType]}
                    </Tag>
                  )}
                </div>
              </div>
              <EiqDetailsRow title="License plate, State" value={getLicensePlateState(initialValues)} />
              <EiqDetailsRow title="Name" value={initialValues?.residentName} />
              {initialValues?.guestId && <EiqDetailsRow title="Guest" value={getCitationGuestName(initialValues)} />}
            </>
          ) : (
            <div className="details">
              <div className="title guestName">
                {getCitationVisitorName(initialValues)}
                <Tag
                  onClick={onTagClick}
                  className="eiq-tag"
                  color={citationStatusColorMap[initialValues?.status as CitationStatus]}
                  style={{ color: '#292B2E' }}>
                  {citationStatusMap[initialValues?.status as CitationStatus]}
                </Tag>
                {initialValues?.guestId && (
                  <Tag
                    className="eiq-tag"
                    color={visitorTypeMapColorMap[initialValues?.ownerType as TrafficLogVisitorType]}
                    style={{ color: '#292B2E' }}>
                    {visitorTypeMap[initialValues?.ownerType as TrafficLogVisitorType]}
                  </Tag>
                )}
              </div>
              <div className="text">{getLicensePlateState(initialValues)}</div>
            </div>
          )}
          <EiqDetailsRow title="Date & Time" value={dayjs(initialValues?.occurredAt).format(DATE_SHORT_MMM_DD_YYYY_H_MMA)} />
          {featureStore.isEIQViolationEnabled && initialValues?.status === CitationStatus.Suspension && (
            <EiqDetailsRow title="Current Suspension" value={`${initialValues?.currentSuspensionDays} days`} />
          )}
          {featureStore.isEIQViolationEnabled && initialValues?.status === CitationStatus.Suspension && (
            <EiqDetailsRow title="Citation Suspension" value={`${initialValues?.totalSuspensionDays} days`} />
          )}
          <EiqDetailsRow title="Speed Limit" value={`${initialValues?.speedLimit} mph`} />
          <div className="eiq-details-row violating-speed-row">
            <div className="eiq-details-row__title">Recorded Speed</div>
            <div className="text violating-speed">
              <ViolationSpeedIcon />
              <span>{initialValues?.violatingSpeed} mph</span>
            </div>
          </div>

          {featureStore.isEIQIntelliTicketEnabled && (
            <EiqDetailsRow
              title="Consequences"
              value={`$${initialValues?.totalPaymentAmount} ${initialValues?.status === CitationStatus.PayNow ? 'Due within 2 weeks' : ''}`}
              className="fine-amount"
            />
          )}
          {featureStore.isEIQViolationEnabled && (
            <EiqDetailsRow
              title="Consequences"
              value={
                initialValues?.status === CitationStatus.Suspension
                  ? `Transponder suspended for ${initialValues?.suspensionDays} days`
                  : 'Just a warning'
              }
              className="fine-amount"
            />
          )}
          {isMobile && getCitationNotes()}
          {featureStore.isEIQIntelliTicketEnabled && (
            <div className="fine">
              <Button type="primary" icon={<PayFine />} disabled={isPaymentDisabled()} onClick={onPayFineClicked}>
                Pay Now
              </Button>
            </div>
          )}
        </div>
        {!isMobile && getCitationNotes()}
      </div>

      {featureStore.isEIQIntelliTicketEnabled && clientSecret && stripePromise && !isPaymentDisabled() && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutModal
            isEdit={false}
            title="Payment"
            isOpen={paymentModal.isOpenModal}
            onClose={paymentModal.hideModal}
            initData={paymentModal.initData}
          />
        </Elements>
      )}
    </div>
  );
});

export default CitationDetails;
