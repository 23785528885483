import { CitationStatus, citationStatusMap } from 'common/enums/citation-status.enum';

export const citationStatusOptions = [
  { value: CitationStatus.PayNow, label: citationStatusMap[CitationStatus.PayNow] },
  { value: CitationStatus.Warning, label: citationStatusMap[CitationStatus.Warning] },
  { value: CitationStatus.PastDue, label: citationStatusMap[CitationStatus.PastDue] },
  { value: CitationStatus.Delinquent, label: citationStatusMap[CitationStatus.Delinquent] },
  { value: CitationStatus.Paid, label: citationStatusMap[CitationStatus.Paid] },
];

export const violationStatusOptions = [
  { value: CitationStatus.Warning, label: citationStatusMap[CitationStatus.Warning] },
  { value: CitationStatus.Suspension, label: citationStatusMap[CitationStatus.Suspension] },
  { value: CitationStatus.Released, label: citationStatusMap[CitationStatus.Released] },
];
