import mobileAppVersionStore from 'common/stores/mobile-app-version/mobile-app-version';
import './update-mobile-version-modal.scss';
import { Capacitor } from '@capacitor/core';
import {
  MOBILE_APP_LATEST_VERSION,
  MODAL_LAST_TIME_CLOSED,
  MODAL_WAS_CLOSED_COUNT,
  MODAL_WAS_CLOSED,
} from 'common/stores/mobile-app-version/mobile-app-version.constants';
import { NativeMarket } from '@capacitor-community/native-market';
import { App } from '@capacitor/app';
import semver from 'semver';
import dayjs from 'dayjs';

export const shouldShowUpdateModal = async (): Promise<boolean> => {
  await mobileAppVersionStore.requestConfiguration();

  const latestVersion = mobileAppVersionStore.mobileAppVersion?.latest;
  const minSupported = mobileAppVersionStore.mobileAppVersion?.minSupported;

  if (!latestVersion || !minSupported) return false;

  const toShowForceUpdate = await isGreaterThenLocalVersion(minSupported);

  if (toShowForceUpdate === true) {
    return toShowForceUpdate;
  }

  const storedLatestVersion = localStorage.getItem(MOBILE_APP_LATEST_VERSION);

  if (storedLatestVersion === latestVersion) {
    // reset close date on app reload
    const modalWasClosed = sessionStorage.getItem(MODAL_WAS_CLOSED);
    if (modalWasClosed !== 'true') {
      localStorage.removeItem(MODAL_LAST_TIME_CLOSED);
    }

    const counter = +(localStorage.getItem(MODAL_WAS_CLOSED_COUNT) || '0');
    const lastTimeClosed = localStorage.getItem(MODAL_LAST_TIME_CLOSED);
    const lastTimeClosedDaysJs = lastTimeClosed ? dayjs(lastTimeClosed) : null;

    if (counter && +counter >= 5) {
      return false;
    }
    if (lastTimeClosedDaysJs && dayjs().diff(lastTimeClosedDaysJs, 'hour') < 1) {
      return false;
    }
  } else {
    localStorage.setItem(MOBILE_APP_LATEST_VERSION, latestVersion);

    localStorage.removeItem(MODAL_WAS_CLOSED_COUNT);
    localStorage.removeItem(MODAL_LAST_TIME_CLOSED);
    sessionStorage.removeItem(MODAL_WAS_CLOSED);
  }

  if (latestVersion) {
    const toShow = await isGreaterThenLocalVersion(latestVersion);
    return toShow;
  }
  return false;
};

export const isGreaterThenLocalVersion = async (versionToCompare: string): Promise<boolean> => {
  try {
    const platform = Capacitor.getPlatform();
    if (platform === 'web') return false;

    const appInfo = await App.getInfo();
    const localVersion = appInfo.version;

    const semverToCompare = semver.coerce(versionToCompare);
    const semverLocal = semver.coerce(localVersion);

    if (!semverToCompare || !semver.valid(semverToCompare)) {
      console.error('Invalid version to compare:', versionToCompare);
      return false;
    }
    if (!semverLocal || !semver.valid(semverLocal)) {
      console.error('Invalid local version', versionToCompare);
      return false;
    }

    const isGreate = semver.gt(semverToCompare, semverLocal!);

    return isGreate;
  } catch {
    console.error("Couldn't compare versions");
    return false;
  }
};

export const redirectToAppStore = async () => {
  let appId = process.env.REACT_APP_IOS_APP_STORE_ID;
  if (Capacitor.getPlatform() === 'android') {
    appId = process.env.REACT_APP_ANDROID_PLAY_MARKET_ID;
  }
  if (!appId) {
    console.error('No appId provided');
    return;
  }
  try {
    await NativeMarket.openStoreListing({ appId: appId });
  } catch (error) {
    console.error('Failed to redirect to app store:', error);
  }
};
