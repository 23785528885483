import { Button, Modal } from 'antd';
import { ModalItemProps } from '../../../../common/components/modal/modal-context/modal-context';
import { useEffect, useState } from 'react';
import mobileAppVersionStore from 'common/stores/mobile-app-version/mobile-app-version';
import { isGreaterThenLocalVersion, redirectToAppStore } from './update-mobile-version-modal.functions';
import CriticalUpdateIcon from '../../../../assets/icons/eiq-connect/critical-update.png';
import NonCriticalUpdateIcon from '../../../../assets/icons/eiq-connect/non-critical-update.png';
import {
  MODAL_LAST_TIME_CLOSED,
  MODAL_WAS_CLOSED,
  MODAL_WAS_CLOSED_COUNT,
} from 'common/stores/mobile-app-version/mobile-app-version.constants';
import dayjs from 'dayjs';

export const UpdateMobileVersionModal = ({ isOpened, handleCloseModal }: ModalItemProps) => {
  const [updateIsRequired, setUpdateIsRequired] = useState<boolean>(false);

  useEffect(() => {
    const handlesetMobileAppConfiguration = async () => {
      await mobileAppVersionStore.requestConfiguration();

      const minSuportedVersion = mobileAppVersionStore.mobileAppVersion?.minSupported;
      if (!minSuportedVersion) return;

      const isUpdateRequired = await isGreaterThenLocalVersion(minSuportedVersion);
      setUpdateIsRequired(isUpdateRequired);
    };
    handlesetMobileAppConfiguration();
  }, [isOpened]);

  // set close date and increase close counter
  const handleButtonClicked = () => {
    sessionStorage.setItem(MODAL_WAS_CLOSED, 'true');

    localStorage.setItem(MODAL_LAST_TIME_CLOSED, dayjs().toISOString());

    const counter = +(localStorage.getItem(MODAL_WAS_CLOSED_COUNT) || '0');
    localStorage.setItem(MODAL_WAS_CLOSED_COUNT, (counter + 1).toString());
  };

  const handleUpdate = async () => {
    handleButtonClicked();
    await redirectToAppStore();
    handleCloseModal();
  };

  const handleNotNow = () => {
    handleButtonClicked();
    handleCloseModal();
  };

  const Update = (
    <Button onClick={handleUpdate} className="update-btn" type="primary">
      Update Now
    </Button>
  );

  const NotNow = (
    <Button onClick={handleNotNow} className="cancel-btn">
      Remind Me Later
    </Button>
  );
  return (
    <Modal open={isOpened} footer={null} centered closable={false} wrapClassName="update-app-modal">
      <div className="modal-body">
        <div className="modal-body-title">
          <h1>Update {updateIsRequired ? `required` : `available`}!</h1>
          <img className="update-icon" src={updateIsRequired ? CriticalUpdateIcon : NonCriticalUpdateIcon} alt="logo" />
        </div>
        <div className="modal-body-text-container">
          {updateIsRequired ? (
            <p>
              This update is needed to keep your app working smoothly. Please update now to continue using all features. You may need to log
              in again after the update.
            </p>
          ) : (
            <p>A new version is here with improvements and new features. Update now for the best experience!</p>
          )}
        </div>
      </div>
      <div className="modal-footer">
        {Update}
        {updateIsRequired ? null : NotNow}
      </div>
    </Modal>
  );
};
