import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as EyeIcon } from 'assets/icons/eiq-connect/eye.svg';
import { ReactComponent as EyeCrossIcon } from 'assets/icons/eiq-connect/eye-cross.svg';
import { IResetPasswordForm, IResetPasswordProps } from './reset-password.types';
import { isEmail } from 'common/helpers/email.helper';
import loginService from 'features/eiq-connect-login/service/login.service';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { resetPassword } from 'features/eiq-connect-login/validation/login.validation';
import styles from '../forgot-password.module.scss';
import { VERIFICATION_CODE_EXPIRATION_TIME_IN_SECONDS } from 'common/constans/common.constants';
import { trackPromise } from 'react-promise-tracker';

const ResetPassword = ({ login }: IResetPasswordProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showResend, setShowResend] = useState(false);
  const [timeLeft, setTimeLeft] = useState(VERIFICATION_CODE_EXPIRATION_TIME_IN_SECONDS);

  useEffect(() => {
    if (timeLeft <= 0) {
      setShowResend(true);
      return;
    } else {
      setShowResend(false);
    }
    const timerId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const onFinish = async (values: IResetPasswordForm) => {
    await loginService.confirmResetPassword(login, values.code, values.password);
    navigate('/new-password');
  };

  const onResend = async () => {
    await trackPromise(loginService.resetPassword(login));
    setTimeLeft(VERIFICATION_CODE_EXPIRATION_TIME_IN_SECONDS);
  };

  const getLifeTime = (time: number) => {
    const minutes = (time / 60) | 0;
    return `${minutes}:${getLifeTimeSeconds(time - minutes * 60)}`;
  };

  const getLifeTimeSeconds = (time: number) => {
    if (time > 9) {
      return time;
    }
    return `0${time}`;
  };

  return (
    <FormWrapper className="eiq-connect-auth-form-wrapper" name="validateOnly" layout="vertical" form={form} onFinish={onFinish}>
      <Row>
        <Col span={24}>
          <div className="title">Check your {isEmail(login) ? 'email' : 'phone'}</div>
          <div className="text">A message with a verification code has been sent to {login}</div>
        </Col>
      </Row>
      <Form.Item name="code" label="Verification code">
        <Input placeholder="Enter verification code" />
      </Form.Item>
      <Form.Item name="password" label="New password (Min 8 characters)" rules={resetPassword.password}>
        <Input.Password
          size={'large'}
          name="password"
          placeholder="Enter new password"
          iconRender={(visible) => {
            if (visible) {
              return <EyeCrossIcon />;
            }
            return <EyeIcon />;
          }}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirm password (Same as the new password)"
        rules={resetPassword.password2}
        dependencies={['password']}>
        <Input.Password
          size={'large'}
          placeholder="Enter confirm password"
          iconRender={(visible) => {
            if (visible) {
              return <EyeCrossIcon />;
            }
            return <EyeIcon />;
          }}
        />
      </Form.Item>
      <div className="action-line ">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className={styles.button}>
          Set new password
        </Button>
      </div>
      <div className="text text-center">
        {showResend ? (
          <Link to="" onClick={onResend}>
            Resend code
          </Link>
        ) : (
          <>
            Resend code after <span className={styles.timer}> {getLifeTime(timeLeft)}</span>
          </>
        )}
      </div>
      <div className={`text text-center ${styles.returnBack}`}>
        <Button
          className={styles.resendBtn}
          type="link"
          onClick={() => {
            navigate(-1);
          }}
          icon={<ArrowLeftOutlined />}>
          Back
        </Button>
      </div>
    </FormWrapper>
  );
};

export default ResetPassword;
