import { Button, Checkbox, Col, Divider, Form, Input, Radio, Row } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { loginValidation } from '../../validation/login.validation';
import { observer } from 'mobx-react-lite';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as LoginIcon } from 'assets/icons/eiq-connect/login.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eiq-connect/eye.svg';
import { ReactComponent as EyeCrossIcon } from 'assets/icons/eiq-connect/eye-cross.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import './login.scss';
import Spinner from '../../../../common/components/base/spinner/spinner';
import { trackPromise } from 'react-promise-tracker';
import { notification } from '../../../../common/utils/notification';
import {
  loginErrorMessage,
  residentDeletionRequestedErrorMessage,
  ResidentRentalDateIsNotValidErrorMessage,
} from 'common/constans/alert-messages.constants';
import { ResidentDeletionRequested, ResidentRentalDateIsNotValid } from 'common/constans/errorCodes.constants';
import { getLogin } from 'common/helpers/login.helper';
import healthCheckService from 'common/services/helthcheck-service/helscheck-service';

export const WGLoginPage = () => {
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem('isSuccessfullySaved');
    notification.destroy();
  }, []);

  const onLoginFailure = async (error: any) => {
    // TODO: Add a server availability check with different error, after EIQ-1080
    const isConnected = await healthCheckService.isNetworkConnected();

    if (!isConnected) {
      notification.error({ message: `No internet connection. Please check your network and try again.` });
      return;
    }

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    if (error?.errorCode === ResidentDeletionRequested) {
      notification.destroy();
      notification.error({ message: residentDeletionRequestedErrorMessage });
    } else if (error?.errorCode === ResidentRentalDateIsNotValid) {
      notification.destroy();
      notification.error({ message: ResidentRentalDateIsNotValidErrorMessage });
    } else {
      notification.destroy();
      notification.error({ message: loginErrorMessage, duration: null });
    }
  };

  const onFinish = (values: any) => {
    trackPromise(userAuthStore.requestTokenForResident(getLogin(values.login), values.password, values.recaptcha, onLoginFailure));
  };

  if (userAuthStore.isLogin) {
    return <Navigate to="/eiq-connect" />;
  }

  const handleKeepMeLoggedInChanged = (event: any) => {
    userAuthStore.setKeepMeLoggedIn(event.target.checked);
  };

  return (
    <>
      <FormWrapper
        className="eiq-connect-auth-form-wrapper"
        form={form}
        onFinish={onFinish}
        name="validateOnly"
        layout="vertical"
        autoComplete="on"
        initialValues={{ remember: true }}>
        <Row>
          <Col>
            <div className="title-wrapper">
              <Button.Group className="full-width" style={{ display: 'flex' }} size="large">
                <Button style={{ flex: 1, fontWeight: 'bold' }} type="primary">
                  Log in
                </Button>
                <Button style={{ flex: 1, fontWeight: 'bold' }} onClick={() => navigate('/sign-up')}>
                  Account activation
                </Button>
              </Button.Group>
            </div>
            <br />
            <div className="text">Please use the email you have registered with your association to log in.</div>
          </Col>
        </Row>

        <div>
          <Form.Item name="login" label="Email" rules={loginValidation.login}>
            <Input name="login" placeholder="Enter email" autoCapitalize="none" autoCorrect="off" />
          </Form.Item>

          <Divider className="auth-diver" />

          <Form.Item name="password" label="Password (Min 8 characters)" rules={loginValidation.password}>
            <Input.Password
              size={'large'}
              name="password"
              placeholder="Enter password"
              iconRender={(visible) => {
                if (visible) {
                  return <EyeCrossIcon />;
                }
                return <EyeIcon />;
              }}
            />
          </Form.Item>
          <div className="keep-me-logged-in">
            <span>Keep me logged in</span>
            <Checkbox checked={userAuthStore.keepMeLoggedIn} onChange={handleKeepMeLoggedInChanged} />
          </div>
          <Form.Item
            name="recaptcha"
            rules={[{ required: true, message: 'Invalid CAPTCHA token. Please complete the CAPTCHA and try again.' }]}>
            <ReCAPTCHA ref={recaptchaRef} className="recaptcha" sitekey={siteKey ?? ''} />
          </Form.Item>
        </div>

        <div className="action-line">
          <Button htmlType="submit" type="primary" icon={<LoginIcon />} style={{ width: '100%' }}>
            Log In
          </Button>
        </div>
        <div className="text text-center">
          <Link to="/forgot-password">Forgot the password?</Link>
        </div>
        <div className="text text-center" style={{ marginTop: '-20px' }}>
          {process.env.REACT_APP_RELEASE_NAME || ''}
        </div>
      </FormWrapper>
      <Spinner />
    </>
  );
};

export const WGLogin = observer(WGLoginPage);
