import React, { FC, useState } from 'react';
import { Button, Modal, Radio, Space, notification } from 'antd';
import { IModal } from 'common/models/model.interface';
import { mapDeleteVehicleReason, getVehicleDeleteReasonsOptions } from 'common/constans/vehicle-delete-reason.options.constants';
import './vehicle-delete-modal.scss';
import { IConfirmDeleteVehicleProps } from './vehicle-delete-modal.types';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { ReactComponent as Trash } from 'assets/icons/eiq-connect/trash-white.svg';
import { IDeleteVehicle } from 'common/services/vehicle-service/vehicle.service.types';
import { DeleteVehicleReasonTypesEnum } from 'common/enums/delete-vehicle-reason-types.enum';

const VehicleDeleteModal: FC<IModal<IConfirmDeleteVehicleProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const [vehicleDeleteReason, setVehicleDeleteReason] = useState<DeleteVehicleReasonTypesEnum>(
    getVehicleDeleteReasonsOptions(false)[0].value,
  );

  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const handleDeleteVehicle = () => {
    if (initData) {
      const { residentId, addressId, vehicle } = initData;
      const data: IDeleteVehicle = {
        reason: mapDeleteVehicleReason(vehicleDeleteReason, false),
        reasonType: vehicleDeleteReason,
      };
      vehicleService.deleteVehicle(residentId, addressId, vehicle.id, data).then((result) => {
        hideModal(true);
      });
    }
  };

  return (
    <Modal
      centered
      title={title}
      width={420}
      open={isOpen}
      wrapClassName="custom-modal"
      className="confirmation-modal vms-vehicle-delete-modal"
      closable={false}>
      <Space className="full-width" size={13}>
        <div className="delete-vehicle-body">
          <span className="warning-message">
            <span className="warning">Warning:</span> Your transponder will be disabled, you won’t be able to use the resident lane, and
            management and security will be notified.
          </span>
          <span className="vehicle-info">
            Are you sure you want to delete <span>{`${initData?.vehicle?.make} ${initData?.vehicle?.model}`}</span> from a vehicles list? If
            so, please choose the reason:
          </span>
        </div>
      </Space>

      <Space>
        <Radio.Group
          value={vehicleDeleteReason}
          onChange={(e) => setVehicleDeleteReason(e.target.value)}
          options={getVehicleDeleteReasonsOptions(false)}
          className="vertical-radio-group"></Radio.Group>
      </Space>

      <Space direction="vertical" className="full-width btn-container">
        <Button type="primary" className="full-width" icon={<Trash />} onClick={handleDeleteVehicle}>
          Delete
        </Button>
        <Button type="link" className="full-width" onClick={() => hideModal(false)}>
          Cancel
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(VehicleDeleteModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
