import { ICitationRead } from 'common/services/citation-service/citation-service.types';

export const getCitationVisitorName = (citation?: ICitationRead) => {
  if (!citation) {
    return '';
  }
  if (citation.companyName) {
    return citation.companyName;
  }
  if (citation.guestName) {
    return citation.guestName;
  }
  return citation.residentName;
};

export const getCitationGuestName = (citation?: ICitationRead) => {
  if (!citation?.guestId) {
    return '';
  }

  if (citation.guestName && citation.companyName) {
    return `${citation.guestName} (${citation.companyName})`;
  }

  if (citation.guestName && !citation.companyName) {
    return citation.guestName;
  }

  if (citation.companyName && !citation.guestName) {
    return citation.companyName;
  }
};
